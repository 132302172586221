import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formattedPrice } from '@omsmastersystem/pavo-intl';

import { useHistory } from 'react-router-dom';
import {
    siteAccessRestrictedDueToNoLogin,
    textColorForBackgroundColorHex,
    UrlEnum,
    isLoggedIn,
    isSalesRepWebsite,
    siteAccessPartiallyRestrictedDueToNoLogin,
    siteAllowsOrdering,
} from 'util/utils';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Popover,
    Typography,
    Menu,
    Grid,
    ListItem,
    ListItemText,
    Box,
    Toolbar,
    ListItemButton,
    IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SubmenuListItem from 'components/HeaderBar/TopBar/SubmenuListItem';
import { Close } from '@mui/icons-material';

function TopBar(props) {
    const auth = useSelector((store) => store.auth);
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const serviceType = useSelector((store) => store.serviceType);
    const category = useSelector((store) => store.category);
    const pavoGlobalFilter = useSelector((store) => store.pavoGlobalFilter);

    const [openFields, setOpenFields] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { menuList } = props;

    const { categories } = category;

    const history = useHistory();

    const backgroundColor = websiteConfiguration.topbar_color;
    const textColor =
        websiteConfiguration.topbar_font_color ?? textColorForBackgroundColorHex(backgroundColor);

    const renderSubCategory = (parent) => {
        let start = categories.findIndex((el) => el.category_id === parent.category_id);
        let subCatList = [];

        for (let i = start + 1; i < categories.length; i++) {
            if (categories[i].level <= parent.level) {
                break;
            } else if (categories[i].level > parent.level + 1) {
                continue;
            }

            subCatList.push(
                <Link
                    onClick={() => {
                        setOpenFields(null);
                    }}
                    key={categories[i].slug}
                    to={`${UrlEnum.PRODUCTS}?category=${encodeURIComponent(categories[i].slug)}`}
                >
                    <Typography gutterBottom>{categories[i].title}</Typography>
                </Link>,
            );
        }

        return subCatList;
    };

    const renderRootCategory = (cat, index) => {
        const { show_category_image } = websiteConfiguration.category_settings;
        const pathname = history.location.pathname;

        return (
            <Grid key={`${cat.title}_${index}`} item xs={2} sm={4} md={4}>
                <Card variant="outlined" sx={{ border: '0px' }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                        {show_category_image && (
                            <Link
                                to={UrlEnum.PRODUCT_CATEGORY_GETTER(
                                    pathname,
                                    encodeURIComponent(cat.slug),
                                )}
                                open={openFields === 'category_list'}
                                onClick={() => {
                                    setOpenFields(null);
                                }}
                            >
                                <img height={150} src={cat.image.medium} alt={cat.title} />
                            </Link>
                        )}
                        <Link
                            to={`${UrlEnum.PRODUCTS}?category=${encodeURIComponent(cat.slug)}`}
                            onClick={() => {
                                setOpenFields(null);
                            }}
                        >
                            <Typography gutterBottom variant="h5">
                                {cat.title}
                            </Typography>
                        </Link>
                        {renderSubCategory(cat)}
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    const renderCategoryMenuItem = (menu, index) => {
        const foundCategory = categories.find((el) => el.category_id === menu.id.toLowerCase());

        if (_.isEmpty(foundCategory)) {
            return null;
        }

        const rootCategories = categories.filter(
            (el) => el.parent_category_id === foundCategory.category_id,
        );

        if (_.isEmpty(rootCategories)) {
            // if there is no root category, just display the button only
            return (
                <Button
                    key={`${menu.id}-${index}`}
                    component={Link}
                    sx={{ color: textColor, alignItems: 'center' }}
                    to={`${UrlEnum.PRODUCTS}?category=${encodeURIComponent(menu.slug)}`}
                    onClick={() => {
                        setOpenFields(null);
                    }}
                >
                    {menu.name || category.title}
                </Button>
            );
        }

        const openFieldIndex = `category_${index}`;

        const id = openFields === openFieldIndex ? 'categories-popover' : undefined;

        return (
            <React.Fragment key={`${menu.id}-${index}`}>
                <Button
                    aria-describedby={id}
                    sx={{ color: textColor, alignItems: 'center' }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={(event) => {
                        setOpenFields(openFieldIndex);
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    {menu.name || category.title}
                </Button>
                <Popover
                    id={id}
                    anchorEl={anchorEl}
                    open={openFields === openFieldIndex}
                    onClose={() => {
                        setOpenFields(null);
                        setAnchorEl(null);
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Box sx={{ minWidth: '600px' }}>
                        <Grid
                            container
                            direction="row"
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                paddingTop: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                        >
                            {rootCategories.map((category, index) => {
                                return renderRootCategory(category, index);
                            })}
                        </Grid>
                    </Box>
                </Popover>
            </React.Fragment>
        );
    };

    const renderCategoryList = () => {
        const { show_category_in_menubar } = websiteConfiguration.category_settings;
        if (isSalesRepWebsite(serviceType)) {
            return null;
        }
        if (!show_category_in_menubar || _.isEmpty(categories)) {
            return null;
        }

        const rootCategories = categories.filter((el) => el.level === 0);

        const id = openFields === 'category_list' ? 'categories-popover' : undefined;

        return (
            <React.Fragment>
                <Button
                    aria-describedby={id}
                    sx={{ color: textColor, alignItems: 'center' }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={(event) => {
                        setOpenFields('category_list');
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    {'Shop by Category'}
                </Button>
                <Popover
                    id={id}
                    anchorEl={anchorEl}
                    open={openFields === 'category_list'}
                    onClose={() => {
                        setOpenFields(null);
                        setAnchorEl(null);
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Box sx={{ minWidth: '600px', position: 'relative' }}>
                        <Grid
                            container
                            direction="row"
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                paddingTop: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                        >
                            <IconButton
                                size="large"
                                sx={{
                                    position: 'absolute',
                                    top: '24px',
                                    right: 0,
                                }}
                                onClick={() => {
                                    setOpenFields(null);
                                    setAnchorEl(null);
                                }}
                            >
                                <Close />
                            </IconButton>
                            {rootCategories.map((category, index) => {
                                return renderRootCategory(category, index);
                            })}
                        </Grid>
                    </Box>
                </Popover>
            </React.Fragment>
        );
    };

    const goLinkAndCloseOpenFields = (url) => {
        setOpenFields(null);
        history.push(url);
    };

    const renderSubmenu = (submenu, menu, showHeader) => {
        let hasSub = _.isEmpty(submenu.subitems) ? false : true;

        function getUrl(items) {
            const subqueries = [];
            for (const key in items) {
                const value = items[key];
                subqueries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
            return `${UrlEnum.PRODUCTS}?${subqueries.join('&')}`;
        }
        // Populate subitemsList
        let subitemsList = [];
        if (hasSub) {
            if (submenu.id === 'PRICE') {
                const prices = pavoGlobalFilter.filters.find((filter) => filter.name === 'price');
                if (!_.isEmpty(prices?.values)) {
                    let prevPriceTo;
                    prices.values.forEach((priceObj, index) => {
                        const { price_to, price_from } = priceObj;
                        prevPriceTo = price_to;
                        subitemsList.push(
                            <SubmenuListItem
                                showHeader={showHeader}
                                key={`${menu.name}-${submenu.name}-prices-${index}`}
                                onClick={() =>
                                    goLinkAndCloseOpenFields(getUrl({ price_to, price_from }))
                                }
                                text={`${formattedPrice(price_from)} to ${formattedPrice(
                                    price_to,
                                )}`}
                            />,
                        );
                    });
                    subitemsList.push(
                        <SubmenuListItem
                            showHeader={showHeader}
                            onClick={() =>
                                goLinkAndCloseOpenFields(getUrl({ price_from: prevPriceTo }))
                            }
                            text={`${formattedPrice(prevPriceTo)}+`}
                        />,
                    );
                }
            } else {
                subitemsList = submenu.subitems?.map((item, index) => {
                    return (
                        <SubmenuListItem
                            showHeader={showHeader}
                            onClick={() =>
                                goLinkAndCloseOpenFields(
                                    getUrl({ [submenu.id.toLowerCase()]: item.name }),
                                )
                            }
                            key={`${item.name}-submenu-${index}`}
                            text={item.description ? item.description : item.name}
                        />
                    );
                });
            }
        }

        return (
            <div key={submenu.name}>
                {showHeader && (
                    <ListItem
                        button={!hasSub}
                        onClick={(event) => {
                            if (!hasSub) {
                                history.push(
                                    `${UrlEnum.PRODUCTS}?${encodeURIComponent(
                                        menu.id.toLowerCase(),
                                    )}=${encodeURIComponent(submenu.name)}`,
                                );
                            }
                        }}
                    >
                        <ListItemText
                            primary={submenu.name}
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                )}
                {subitemsList}
            </div>
        );
    };

    const renderMenuItem = (menu, index) => {
        let hasSub = _.isEmpty(menu.subitems) ? false : true;
        let toLink = null;
        if (menu.slug) {
            toLink = `${UrlEnum.PRODUCTS}?slug=${encodeURIComponent(menu.slug)}`;
        } else if (menu.link) {
            let extLink = menu.link;
            if (!extLink.startsWith('http://') && !extLink.startsWith('https://')) {
                extLink = `https://${extLink}`;
            }
            toLink = { pathname: extLink };
        } else {
            toLink = '#';
        }

        const menuListIndex = `menuList-${index}`;

        const id = openFields === menuListIndex ? 'categories-popover' : undefined;

        return (
            <React.Fragment key={menuListIndex}>
                <Button
                    aria-describedby={id}
                    sx={{ color: textColor, alignItems: 'center' }}
                    endIcon={hasSub ? <KeyboardArrowDownIcon /> : null}
                    onClick={(event) => {
                        if (menu.link) {
                            window.open(toLink.pathname);
                        } else {
                            setOpenFields(menuListIndex);
                            setAnchorEl(event.currentTarget);
                        }
                    }}
                >
                    {menu.name}
                </Button>
                {hasSub && (
                    <Menu
                        anchorEl={anchorEl}
                        open={openFields === menuListIndex}
                        onClose={() => {
                            setOpenFields(null);
                            setAnchorEl(null);
                        }}
                    >
                        {menu.subitems.map((submenu, vl_index) => {
                            const length = menu.subitems.length;
                            if (submenu.slug) {
                                return (
                                    <ListItemButton
                                        key={`${submenu.slug}-header-${vl_index}`}
                                        onClick={() => {
                                            history.push(
                                                `${UrlEnum.PRODUCTS}?slug=${encodeURIComponent(
                                                    submenu.slug,
                                                )}`,
                                            );
                                            setOpenFields(null);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        <ListItemText primary={submenu.name} />
                                    </ListItemButton>
                                );
                            }
                            if (submenu.link) {
                                if (
                                    !submenu.link.startsWith('http://') &&
                                    !submenu.link.startsWith('https://')
                                ) {
                                    submenu.link = `https://${submenu.link}`;
                                }
                                return (
                                    <ListItem
                                        key={vl_index}
                                        button
                                        onClick={() => {
                                            window.open(submenu.link);
                                            setOpenFields(null);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        <ListItemText primary={submenu.name} />
                                    </ListItem>
                                );
                            }
                            return renderSubmenu(submenu, menu, length > 1);
                        })}
                    </Menu>
                )}
            </React.Fragment>
        );
    };

    const renderMenuList = () => {
        if (isSalesRepWebsite(serviceType)) {
            return null;
        }
        if (_.isEmpty(menuList)) {
            return null;
        }

        return menuList.map((menu, index) => {
            try {
                if (menu.type === 'Category') {
                    return renderCategoryMenuItem(menu, index);
                } else {
                    return renderMenuItem(menu, index);
                }
            } catch (error) {
                return null;
            }
        });
    };

    const allowsOrdering = siteAllowsOrdering(websiteConfiguration);

    const shouldDisplayProducts =
        !siteAccessRestrictedDueToNoLogin(websiteConfiguration, auth) &&
        !siteAccessPartiallyRestrictedDueToNoLogin(websiteConfiguration, auth) &&
        allowsOrdering;
    return (
        <AppBar
            position="relative"
            elevation={0}
            sx={{
                backgroundColor,
                color: textColor,
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', width: '100%', maxWidth: '1600px' }}>
                    {allowsOrdering && renderCategoryList()}
                    {allowsOrdering && renderMenuList()}
                    {shouldDisplayProducts && (
                        <>
                            <Button
                                sx={{ my: 2, color: textColor }}
                                onClick={() => history.push(UrlEnum.PRODUCTS)}
                            >
                                {'Products'}
                            </Button>
                            {isLoggedIn(auth) && (
                                <Button
                                    sx={{ my: 2, color: textColor }}
                                    onClick={() => history.push(UrlEnum.QUICK_ADD_PRODUCTS)}
                                >
                                    {'Quick Add'}
                                </Button>
                            )}
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    menuList: PropTypes.array.isRequired,
};

export default TopBar;
